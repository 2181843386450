<template>
  <el-form
      class="login-form"
      status-icon
      :rules="loginRules"
      ref="form"
      :model="form"
      label-width="0">
    <el-form-item prop="mobile">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          v-model="form.mobile"
          auto-complete="off"
          placeholder="请输入手机号码">
        <i
            slot="prefix"
            class="el-icon-phone"></i>
      </el-input>
    </el-form-item>
    <el-form-item prop="verifyCode">
      <el-input
          size="small"
          v-model="form.verifyCode"
          auto-complete="off"
          placeholder="请输入验证码">
        <i
            slot="prefix"
            class="el-icon-chat-square"
            style="margin-top:6px;"></i>
        <template slot="append">
          <span
              @click="handleSend"
              class="msg-text"
              :class="[{display:msgKey}]">{{ msgText }}</span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
          size="small"
          @keyup.enter.native="handleLogin"
          :type="passwordType"
          v-model="form.password"
          auto-complete="off"
          placeholder="密码">
        <i
            class="el-icon-view el-input__icon"
            slot="suffix"
            @click="showPassword"></i>
        <i
            slot="prefix"
            class="el-icon-lock"></i>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button
          size="small"
          type="primary"
          @click.native.prevent="handleLogin"
          class="login-submit">确认提交
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { isValidateMobile } from '@/util/validate'
import { mapGetters } from 'vuex'
import { sendSms, passwordSms } from '../../api/login'

export default {
  name: 'ChangePassword',
  data () {
    const validatePhone = (rule, value, callback) => {
      if (isValidateMobile(value)[0]) {
        callback(new Error(isValidateMobile(value)[1]))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 4) {
        callback(new Error('请输入4位数的验证码'))
      } else {
        callback()
      }
    }
    return {
      msgText: '',
      msgTime: '',
      msgKey: false,
      form: {
        mobile: '',
        verifyCode: '',
        password: ''
      },
      loginRules: {
        mobile: [{ required: true, trigger: 'blur', validator: validatePhone }],
        verifyCode: [{ required: true, trigger: 'blur', validator: validateCode }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, message: '密码长度最少为6位', trigger: 'blur' }
        ]
      },
      passwordType: 'password'
    }
  },
  created () {
    this.msgText = this.config.MSG_INIT
    this.msgTime = this.config.MSG_TIME
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['tagWel']),
    config () {
      return {
        MSG_INIT: '发送验证码',
        MSG_SUCCESS: '秒后重发',
        MSG_TIME: 60
      }
    }
  },
  props: [],
  methods: {
    showPassword () {
      this.passwordType === ''
        ? (this.passwordType = 'password')
        : (this.passwordType = '')
    },
    handleSend () {
      if (this.msgKey) return
      const valid = isValidateMobile(this.form.mobile)
      if (valid[0]) {
        this.$message.error(valid[1])
        return
      }
      sendSms(this.form.mobile).then(res => {
        if (res.success) {
          this.msgText = this.msgTime + this.config.MSG_SUCCESS
          this.msgKey = true
          const time = setInterval(() => {
            this.msgTime--
            this.msgText = this.msgTime + this.config.MSG_SUCCESS
            if (this.msgTime === 0) {
              this.msgTime = this.config.MSG_TIME
              this.msgText = this.config.MSG_INIT
              this.msgKey = false
              clearInterval(time)
            }
          }, 1000)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleLogin () {
      this.$refs.form.validate(valid => {
        if (valid) {
          passwordSms(this.form).then(res => {
            if (res.success) {
              this.$message.success('密码修改成功，请登录')
              this.$emit('suc')
            } else {
              this.$message.error('手机号码不存在，请联系运营人员')
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.msg-text {
  display: block;
  width: 60px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.msg-text.display {
  color: #ccc;
}
</style>
