<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-left">
        <div class="login-time">
          {{ time }}
        </div>
        <img
            class="img"
            src="~@/assets/img/logo.png"
            alt="">
        <p class="title">中汇数农-智慧农贸管理平台</p>
      </div>
      <div class="login-border">
        <div class="login-main">
          <h4 v-if="activeName==='login'" class="login-title">
            登录{{ title }}
          </h4>
          <h4 v-if="activeName==='pwd'" class="login-title">
            找回密码
          </h4>
          <userLogin v-if="activeName==='login'"></userLogin>
          <changePassword v-else-if="activeName==='pwd'" @suc="suc"></changePassword>
          <div class="login-menu">
            <a
                v-if="activeName==='login'"
                href="#"
                @click.stop="activeName='pwd'">找回密码</a>
            <a
                v-if="activeName==='pwd'"
                href="#"
                @click.stop="activeName='login'">返回登录</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from './userlogin'
import changePassword from './changePassword'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/util/date'
import { title } from '@/config'

export default {
  name: 'Login',
  components: {
    changePassword,
    userLogin
  },
  data () {
    return {
      time: '',
      activeName: 'login',
      title: title
    }
  },
  created () {
    this.getTime()
    setInterval(() => {
      this.getTime()
    }, 1000)
  },
  mounted () {
  },
  computed: {
    ...mapGetters([])
  },
  props: [],
  methods: {
    suc () {
      this.activeName = 'login'
    },
    getTime () {
      this.time = dateFormat(new Date())
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/login.scss";
</style>
